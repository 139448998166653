import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import hero3xl from "../assets/img/hero-3xl.webp";
import hero2xl from "../assets/img/hero-2xl.webp";
import heroxl from "../assets/img/hero-xl.webp";
import herolg from "../assets/img/hero-lg.webp";
import heromd from "../assets/img/hero-md.webp";
import herosm from "../assets/img/hero-sm.webp";
import Footer from "../components/Footer";
// import Features from "../components/Features";
import Navbar from "../components/Navbar";
import sticker1 from "../assets/img/sticker1.png";
import sticker2 from "../assets/img/sticker2.png";
import sticker3 from "../assets/img/sticker3.png";
import sticker4 from "../assets/img/sticker4.png";
import sticker5 from "../assets/img/sticker5.png";
import sticker6 from "../assets/img/sticker6.png";
import { Draggable } from "gsap/Draggable";
import gsap from "gsap";

gsap.registerPlugin(Draggable);

function Home() {

  const [hiddenDiv, setHiddenDiv] = useState(false);
  const stickerRefs = useRef([]);

  useEffect(() => {
    stickerRefs.current.forEach((ref, index) => {
      Draggable.create(ref, {
        bounds: ".sticker-container",
        inertia: true,
      });
    });
  }, []);

  useEffect(() => {
    // import edilmedi hata verdi require ile kütüphaneyi çektik.
    const skrollrInstance = require("skrollr");
    // kütüphanenin örneğini oluşturuyor
    const instance = skrollrInstance.init({
      // mobile, dokunmatik ekranlarda devre dışı bırakıyoruz.
      mobileCheck: () => {
        return false;
      },
    });
    // burası da oluşturulan örneğini kaldırıyor.
    return () => instance.destroy();
  }, []);

  useEffect(() => {
    const gsapTimeLine = gsap.timeline();
    gsapTimeLine.from(".animation-black-div", {
      opacity: 1,
      duration: 0.1,
    }).to(".animation-black-div", {
      opacity: 0,
      duration: 0.1,
      onComplete: () => setHiddenDiv(true)
    })
  })

  return (
    <>
      <div className={`${hiddenDiv ? 'hidden ' : ''} animation-black-div h-screen w-full bg-black fixed z-[99999]`}></div>
    <div className="sticker-container">
      <div className="bg-white flex justify-between flex-col">
        {/* NavBar */}
        <Navbar />
        {/* İcerik kısmı burası olacak. */}
        <div className="flex items-center justify-center">
          {/* aspect kullanılabilir ama kullanılmadığında tam oturuyor. */}
          <div className="h-full w-full overflow-hidden">
            <section>
              <div className="relative aspect-[376/767] md:aspect-[752/1256] lg:aspect-[1024/1407] xl:aspect-[1408/1408] 2xl:aspect-[1648/1408] 3xl:aspect-[2160/1408]">
                <picture>
                  <source media="(min-width: 1888px)" srcSet={hero3xl} />
                  <source media="(min-width: 1648px)" srcSet={hero2xl} />
                  <source media="(min-width: 1408px)" srcSet={heroxl} />
                  <source media="(min-width: 1000px)" srcSet={herolg} />
                  <source media="(min-width: 700px)" srcSet={heromd} />
                  <source media="(min-width: 384px)" srcSet={herosm} />
                  <source srcSet={herosm} />
                  <img
                    src={herosm}
                    alt="Açıklama"
                    className="spread object-cover opacity-0 ease-o6"
                    style={{ opacity: 1 }}
                    />
                </picture>
              </div>
            </section>
          </div>
        </div>
        <div className="h-[640px] relative">
          {/* Burning event sticker */}
          <img
            ref={(el) => (stickerRefs.current[0] = el)}
            className="
            min-w-[100px] w-[50%] bottom-[60%] left-0
            sm:w-[40%] sm:bottom-[50%] 
            md:w-[45%] md:left-10  md:bottom-[50%] 
            lg:w-[40%] lg:bottom-[50%] 
            xl:w-[35%] xl:bottom-[30%]
            2xl:w-[30%] 2xl:left-32 2xl:bottom-[20%]  
            sticker absolute"
            src={sticker1}
            />
          {/* Flowers sticker */}
          <img
            ref={(el) => (stickerRefs.current[1] = el)}
            className="
            w-[40%] left-[35%] top-[45%]
            sm:w-[40%] sm:top-[40%]
            md:w-[30%] md:top-[37%]
            lg:w-[30%] lg:top-[10%]
            2xl:w-[20%] 2xl:top-[30%] 2xl:left-[50%]
            absolute sticker"
            src={sticker2}
            />
          {/* Newspaper sticker */}
          <img
            ref={(el) => (stickerRefs.current[2] = el)}
            className="
            w-[25%] left-[75%] top-[69%] 
            sm:w-[25%] sm:left-[75%] sm:top-[69%]
            md:w-[25%] md:left-[70%] md:top-[55%]
            lg:w-[20%] lg:left-[70%]
            xl:w-[20%] xl:top-[40%] xl:left-[70%]
            2xl:w-[15%] 2xl:top-[40%] 2xl:left-[75%]
            sticker absolute"
            src={sticker3}
            />
          {/* Şapkalı adam stickerı */}
          <img
            ref={(el) => (stickerRefs.current[3] = el)}
            className="
            w-[35%] left-[60%]
            sm:w-[25%] sm:left-[60%] 
            md:w-[25%] md:left-[55%] md:bottom-[70%]
            lg:w-[20%] lg:left-[65%] lg:bottom-[70%]
            xl:w-[20%] xl:left-[65%] xl:bottom-[60%]
            2xl:w-[15%] 2xl:left-[70%] 2xl:bottom-[60%] 
            sticker absolute"
            src={sticker4}
          />
          {/* note stickker */}
          <img
            ref={(el) => (stickerRefs.current[4] = el)}
            className="
            w-[20%] left-[20%] bottom-[10%] 
            sm:w-[20%] sm:left-[20%] sm:bottom-[10%]
            md:w-[20%] md:left-[20%] md:bottom-[10%]
            lg:w-[20%] lg:left-[30%] lg:bottom-[10%]
            xl:w-[13%] xl:left-[30%] xl:bottom-[30%]
            2xl:w-[13%] 2xl:left-[30%] 2xl:bottom-[20%] 
            absolute sticker"
            src={sticker5}
            />
          {/* Rabbit sticker */}
          <img
            ref={(el) => (stickerRefs.current[5] = el)}
            className="
            w-[20%] left-[70%] bottom-[50%]
            sm:w-[20%] sm:left-[70%] sm:bottom-[40%]
            md:w-[15%] md:left-[80%] md:bottom-[50%]
            lg:w-[13%] lg:left-[85%] lg:bottom-[50%]
            xl:w-[10%] xl:left-[85%] xl:bottom-[60%]
            2xl:w-[10%] 2xl:left-[85%] 2xl:bottom-[50%]
            absolute sticker"
            src={sticker6}
            />
        </div>
      </div>
      {/* <Features /> */}
      <Footer />
    </div>
            </>
  );
}

export default Home;
