import axios from 'axios';


const api = axios.create({baseURL:"http://localhost:3001"});

const postEmail = async (email) => {
    try {
        const response = await api.post("/user/email", {
            email: email
        });
        return response;
    } catch (error) {
        console.log("Mail alırken hata oluştu")
    }
}


export default postEmail;