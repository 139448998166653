import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import nindouMini from "../assets/img/nindou-mini.svg";
import nindouIcon from "../assets/img/nindou-v-white.svg";
import "../assets/styles/search-input.css";
import "../assets/styles/all-styles.css";
import Newsletter from "./Newsletter";
import gsap from "gsap";
import fadeRight from "../assets/anims/fadeRight";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import MobileNavbar from "./mobile/MobileNavbar";
import "../assets/styles/fonts.css";

function Navbar() {
  const comp = useRef(null);
  const deneme = useRef(null);
  const listRef = useRef(null);
  const [hoverNav, setHoverNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [navbarOpen, setIsNavbarOpen] = useState(false);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      // Anims dosyasından çektim bu fonksiyonu..
      fadeRight(
        "#nav-d-primary, .nav-d-primary-title div, .y-w div, form",
        comp
      );
    });
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    const hoverNavbar = gsap.timeline();

    if (hoverNav) {
      hoverNavbar
        .from("#hoverNavBar", {
          opacity: 0,
          y: "-=2",
          animationDelay: 1,
        })
        .to("#hoverNavBar", {
          opacity: 1,
          y: 0,
          animationDelay: 1,
        });
    }
  }, [hoverNav]);

  const handleHover = (hovered) => {
    const listElementHovered = gsap.timeline();
    if (hovered) {
      setHoverNav(true);
      listElementHovered.to(".y-w, #nav-link", {
        opacity: 1,
        y: 0,
        duration: 0.3,
        pointerEvents: "auto",
        // stagger: 0.1,
      });
    } else {
      if (prevScrollPos == 0) {
        setIsNavbarOpen(true);
      } else {
        setHoverNav(false);
        listElementHovered.to(".y-w, #nav-link", {
          opacity: 0,
          y: -10,
          duration: 0.3,
          pointerEvents: "none",
          // stagger: 0.1,
        });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos > prevScrollPos;

      if (!isScrollingDown) {
        setIsNavbarOpen(true);
      } else {
        setIsNavbarOpen(false);
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const listElementHidden = gsap.timeline();

    // koşul ekledim navbar en tepedeyse opactiy 1 olacak eğer ki scroll aşağı hareket ederse
    // opactiy 0 olacak ve navbar da ki elemanlar gizlenecek.
    // diğer türlüde opacity 1 olucak yani sitenin en üstüne gelindiğinde elemanlar açılacak.
    if (prevScrollPos > 0) {
      listElementHidden.to(".y-w, #nav-link, #nav-d-menu-newsletter-form", {
        opacity: 0,
        pointerEvents: "none",
        y: "-=2",
        animationDelay: 0.3,
      });
    } else {
      listElementHidden.to(".y-w, #nav-link, #nav-d-menu-newsletter-form", {
        opacity: 1,
        y: "0",
        pointerEvents: "auto",
        delay: 0.3,
        animationDelay: 0.3,
      });
    }
  }, [prevScrollPos]);

  return (
    // wrap-ml class'ını kontrol edersin sonra
    // açılan beyaz sayfa olacak burası hover olduğunda...
    //! BoxShadow eklemeyi unutma..
    <>
      {(() => {
        if (hoverNav && prevScrollPos > 0) {
          return (
            <div
              ref={deneme}
              id="hoverNavBar"
              className="opacity-1 gap-x-4 lg:grid top-[4px] md:grid-cols-8 wrap-ml lg:grid-cols-12 fixed pointer-events-none z-[9990]"
            >
              <div className="col-span-7 xl:col-span-4 -ml-4">
                <div id="nav-d-bg-primary" className="ml-1 w-[calc(100%+12px)]">
                  <img
                    className="w-[30px]  absolute bottom-4 left-3"
                    src={nindouMini}
                    alt="Nindou Mini"
                  />
                </div>
              </div>
            </div>
          );
        } else if (hoverNav && prevScrollPos == 0) {
          setHoverNav(false);
        }
      })()}

      <MobileNavbar />

      <nav ref={comp} id="nav-d" className="lg:grid">
        <div className="wrap-ml absolute left-0 z-20" id="nav-newsletter">
          <div className="gap-x-4 grid md:grid-cols-8 lg:grid-cols-12">
            {/* newsletter */}
            <div
              id="nav-d-menu-newsletter-form"
              className="newsletter-form is-complete newsletter-form-dark"
            >
              <Newsletter />
            </div>
          </div>
        </div>
        <div className="wrap-ml relative z-10">
          <div className="gap-x-4 grid md:grid-cols-8 lg:grid-cols-12">
            {/* logotype */}
            <div id="nav-d-logotype" className="lg:col-span-2 xl:col-span-1">
              <a href="#" aria-label="">
                <img src={nindouIcon} className="w-[100px]" />
              </a>
            </div>
            {/* primary wrap */}
            <div
              ref={listRef}
              id="nav-d-primary-w"
              className="m-0 col-span-6 xl:col-span-7 "
            >
              {/* primary */}
              <ul
                onMouseLeave={() => handleHover(false)}
                id="nav-d-primary"
                className="flex xl:grid grid-cols-7 xl:col-span-6 xl:gap-x-4"
                style={{ pointerEvents: "all" }}
              >
                <li className="sm:mr-28 mr-0 first:w-[27.5%] second:w-[27.5%] xl:!w-[initial] xl:col-span-1">
                  <div className="nav-d-primary-title">
                    <div
                      onMouseEnter={() => handleHover(true)}
                      className="y"
                      style={{
                        fontFamily: "Roobert600",
                        transform: "translate3d(0px, 0%, 0px)",
                      }}
                    >
                      Nindou
                    </div>
                  </div>
                  <div
                    style={{ fontFamily: "Roobert400" }}
                    className="nav-d-primary-link"
                  >
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Home
                          </div>
                        </div>
                      </div>
                    </a>
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            About
                          </div>
                        </div>
                      </div>
                    </a>
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Explorer
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="sm:mr-28 mr-0 first:w-[27.5%] second:w-[27.5%] xl:!w-[initial] xl:col-span-1">
                  <div className="nav-d-primary-title">
                    <div
                      onMouseEnter={() => handleHover(true)}
                      className="y"
                      style={{
                        fontFamily: "Roobert600",
                        transform: "translate3d(0px, 0%, 0px)",
                      }}
                    >
                      Club
                    </div>
                  </div>
                  <div
                    style={{ fontFamily: "Roobert400" }}
                    className="nav-d-primary-link"
                  >
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Buy
                          </div>
                        </div>
                      </div>
                    </a>
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Community
                          </div>
                        </div>
                      </div>
                    </a>
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Careers
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="sm:mr-28 mr-0 first:w-[27.5%] second:w-[27.5%] xl:!w-[initial] xl:col-span-1">
                  <div className="nav-d-primary-title">
                    <div
                      onMouseEnter={() => handleHover(true)}
                      className="y"
                      style={{
                        fontFamily: "Roobert600",
                        transform: "translate3d(0px, 0%, 0px)",
                      }}
                    >
                      Resources
                    </div>
                  </div>
                  <div
                    style={{ fontFamily: "Roobert400" }}
                    className="nav-d-primary-link"
                  >
                    <a id="nav-link" href=" #" target="_blank" rel="noopener">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Support
                          </div>
                        </div>
                      </div>
                    </a>
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Downloads
                          </div>
                        </div>
                      </div>
                    </a>
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Terms
                          </div>
                        </div>
                      </div>
                    </a>
                    <a id="nav-link" href="#">
                      <div>
                        <div className="y-w">
                          <div
                            className="hover:text-white y text-[#767676]"
                            style={{ transform: "translate3d(0px, 0%, 0px)" }}
                          >
                            Privacy
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-span-4 relative">
              {/* order */}
              <div id="nav-d-order" className="grid grid-cols-6 gap-x-4">
                {/* logomark */}
                <div id="nav-d-order-logomark" className="col-span-3">
                  <div className="grid-cols-3 flex">
                    <a href="#">
                      <FaDiscord className="mb-4 w-[25px]" />
                    </a>
                    <a href="#">
                      <FaTwitter className="mb-4 ml-3 w-[25px]" />
                    </a>
                    <a href="#">
                      <AiFillInstagram className="mb-4 ml-3 w-[25px]" />
                    </a>
                  </div>
                </div>
                <div className="col-start-3 xl:col-start-4 col-span-2 xl:col-span-1">
                  <a
                    id="bulletin-button"
                    className="btn btn-dark invert opacity-0 duration-200 ease-default transition-opacity"
                    href=" #"
                    aria-hidden="true"
                    style={{ opacity: 0, pointerEvents: "none" }}
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
