import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as NindouText } from "../../assets/img/nindou-text.svg";
import { CgMenuGridR } from "react-icons/cg";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
// import mint from "../../assets/img/mint.png";
// import community from "../../assets/img/community.png";
// import world from "../../assets/img/world.png";
import gsap from "gsap";
import "../../assets/styles/search-input.css";
import { FaArrowRightLong } from "react-icons/fa6";
import "../../assets/styles/fonts.css";
import "../../assets/styles/mobile-nav-menu.css";
function MobileNavbar() {
  const [open, setOpen] = useState(false);
  const navMenuRef = useRef(null);

  useEffect(() => {
    const navMenu = navMenuRef.current;
    if (open) {
      gsap.to(navMenu, {
        opacity: 1,
        y: "0",
        duration: 0.3,
        ease: "power3.out",
      });
    }
  }, [open]);

  useEffect(() => {
    const itemGsap = gsap.timeline();

    if (open) {
      itemGsap
        .from(
          ".mobile-m-product-items, #navigationElements, #mobile-m-footer",
          {
            opacity: 0,
            y: "-=3",
            animationDelay: 0.3,
            stagger: 0.3,
          }
        )
        .to(".mobile-m-product-items, #navigationElements, #mobile-m-footer", {
          opacity: 1,
          y: "0",
          animationDelay: 0.3,
          stagger: 0.3,
        });
    }
  }, [open]);

  const toggleMenu = () => {
    if (!open) {
      setOpen(!open);
    } else {
      const itemGsap = gsap.timeline();
      itemGsap
        .from("#nav-menu", {
          opacity: 1,
          y: "0",
          animationDelay: 0.1,
        })
        .to("#nav-menu", {
          opacity: 0,
          y: "-=10",
          animationDelay: 0.1,
          onComplete: () => {
            setOpen(!open);
          },
        });

    }
  };

  return (
    <>
      <nav id="nav-m-bar" className={open ? "border-fx nav-offset-0" : ''} style={{ pointerEvents: "all" }}>
        <a href="/" id="nav-m-logotype">
         <NindouText fill="white" />
        </a>
        <div id="nav-m-burger" onClick={toggleMenu}>
          {open && (
            /*<IoClose
              size={28}
              className="h-7 w-7 z-10 cursor-pointer"
              color="white"
              onClick={toggleMenu}
            />*/
            <svg viewBox="0 0 43.948 43.948"
              color="white"
            >
              <polygon class="ho-svg-poly" points="21.97 18.52 22.33 18.87 22.83 19.38 23.7 20.25 24.56 21.11 25.07 21.62 25.43 21.97 25.07 22.33 24.56 22.84 23.7 23.7 22.84 24.56 22.34 25.06 21.97 25.43 21.62 25.07 21.11 24.56 20.25 23.7 19.38 22.83 18.87 22.33 18.52 21.97 18.89 21.61 19.39 21.11 20.25 20.25 21.11 19.39 21.62 18.87"></polygon>
              <polygon class="ho-svg-poly" points="10.05 6.59 10.96 7.51 11.9 8.45 12.9 9.45 13.88 10.43 14.72 11.27 15.72 12.26 16.72 13.27 15.86 14.13 14.99 14.99 14.13 15.86 13.27 16.72 12.36 15.81 11.35 14.8 10.39 13.84 9.45 12.9 8.61 12.06 7.6 11.05 6.67 10.13 5.6 9.05 6.46 8.19 7.33 7.33 8.19 6.46 9.05 5.6"></polygon>
              <polygon class="ho-svg-poly" points="17.1 13.65 17.91 14.45 18.61 15.16 19.35 15.89 20.08 16.62 20.86 17.41 21.61 18.16 22.33 18.87 21.46 19.74 20.6 20.6 19.74 21.46 18.87 22.33 18.16 21.62 17.37 20.83 16.65 20.1 15.89 19.35 15.12 18.57 14.4 17.85 13.65 17.1 12.91 16.36 13.78 15.5 14.64 14.64 15.5 13.78 16.36 12.91"></polygon>
              <polygon class="ho-svg-poly" points="33.9 6.6 34.9 5.6 35.76 6.46 36.62 7.33 37.48 8.18 38.35 9.05 37.35 10.05 36.46 10.94 35.5 11.9 34.51 12.89 33.51 13.9 32.68 14.72 31.69 15.71 30.67 16.73 29.8 15.86 28.95 15 28.08 14.13 27.22 13.28 28.15 12.35 29.18 11.32 30.12 10.38 31.06 9.44 32.08 8.42 32.98 7.52"></polygon>
              <polygon class="ho-svg-poly" points="26.84 13.66 27.57 12.92 28.43 13.78 29.3 14.65 30.16 15.51 31.03 16.38 30.29 17.11 29.44 17.96 28.75 18.65 28.05 19.35 27.32 20.08 26.49 20.91 25.79 21.61 25.07 22.33 24.21 21.46 23.35 20.6 22.48 19.73 21.62 18.87 22.4 18.09 23.22 17.28 23.91 16.58 24.59 15.9 25.28 15.21 26.04 14.45"></polygon>
              <polygon class="ho-svg-poly" points="31.52 28.07 32.57 29.12 33.47 30.02 34.5 31.05 35.38 31.93 36.33 32.88 37.24 33.79 38.35 34.9 37.49 35.76 36.62 36.62 35.76 37.49 34.9 38.35 33.79 37.24 32.88 36.33 31.92 35.37 31.05 34.5 29.95 33.4 29.01 32.46 28.1 31.55 27.21 30.66 28.07 29.8 28.93 28.93 29.8 28.07 30.66 27.21"></polygon>
              <polygon class="ho-svg-poly" points="25.79 22.34 26.59 23.13 27.29 23.84 28.03 24.58 28.84 25.39 29.55 26.1 30.28 26.83 31.03 27.57 30.16 28.44 29.29 29.29 28.44 30.16 27.57 31.03 26.83 30.28 26.08 29.53 25.28 28.74 24.58 28.03 23.8 27.25 23.11 26.56 22.41 25.86 21.62 25.07 22.48 24.21 23.35 23.35 24.21 22.48 25.07 21.62"></polygon>
              <polygon class="ho-svg-poly" points="12.32 28.17 13.28 27.22 14.13 28.08 15 28.95 15.86 29.8 16.73 30.67 15.8 31.6 14.99 32.41 13.91 33.49 12.89 34.51 12.09 35.31 11.1 36.3 10.01 37.39 9.05 38.35 8.18 37.48 7.33 36.62 6.46 35.76 5.6 34.9 6.66 33.84 7.66 32.84 8.64 31.86 9.44 31.06 10.52 29.98 11.46 29.03"></polygon>
              <polygon class="ho-svg-poly" points="18.13 22.37 18.89 21.61 19.74 22.47 20.61 23.34 21.47 24.19 22.34 25.06 21.69 25.71 20.94 26.46 20.09 27.31 19.36 28.04 18.68 28.72 17.97 29.43 17.11 30.29 16.38 31.03 15.51 30.16 14.64 29.29 13.78 28.43 12.92 27.57 13.66 26.84 14.37 26.12 15.13 25.36 15.91 24.58 16.67 23.83 17.42 23.08"></polygon>
            </svg>
          )}
          {!open && (
            /*<CgMenuGridR
              size={28}
              className="h-7 w-7 z-10 cursor-pointer"
              color="white"
              onClick={toggleMenu}
            /> */
            <svg viewBox="0 0 43.948 43.948" 
              color="white"
            >
              <polygon class="ho-svg-poly" points="21.974,17.091 23.238,17.256 24.416,17.745 25.427,18.521 26.203,19.532 26.692,20.71 26.857,21.974 26.692,23.238 26.204,24.416 25.427,25.427 24.416,26.203 23.238,26.692 21.974,26.857 20.71,26.692 19.532,26.204 18.521,25.427 17.745,24.416 17.256,23.238 17.091,21.974 17.256,20.71 17.744,19.532 18.521,18.521 19.532,17.745 20.71,17.256"></polygon>
              <polygon class="ho-svg-poly" points="7.325,2.442 8.589,2.607 9.767,3.095 10.777,3.872 11.554,4.883 12.042,6.061 12.208,7.325 12.043,8.589 11.554,9.767 10.778,10.778 9.766,11.554 8.589,12.042 7.325,12.208 6.061,12.042 4.883,11.554 3.872,10.778 3.095,9.767 2.607,8.589 2.442,7.325 2.607,6.061 3.095,4.883 3.872,3.872 4.883,3.095 6.061,2.607"></polygon>
              <polygon class="ho-svg-poly" points="7.325,17.091 8.589,17.256 9.767,17.745 10.777,18.521 11.554,19.532 12.042,20.71 12.208,21.974 12.043,23.238 11.554,24.416 10.778,25.427 9.766,26.203 8.589,26.692 7.325,26.857 6.061,26.692 4.883,26.204 3.872,25.427 3.095,24.416 2.607,23.238 2.442,21.974 2.607,20.71 3.095,19.532 3.872,18.521 4.883,17.745 6.061,17.256"></polygon>
              <polygon class="ho-svg-poly" points="36.623,2.442 37.887,2.607 39.065,3.095 40.076,3.872 40.853,4.883 41.341,6.061 41.506,7.325 41.341,8.589 40.853,9.767 40.076,10.778 39.065,11.554 37.887,12.042 36.623,12.208 35.359,12.042 34.181,11.554 33.17,10.778 32.394,9.767 31.906,8.589 31.74,7.325 31.906,6.061 32.394,4.883 33.17,3.872 34.181,3.095 35.359,2.607"></polygon>
              <polygon class="ho-svg-poly" points="21.974,2.442 23.238,2.607 24.416,3.095 25.427,3.872 26.203,4.883 26.692,6.061 26.857,7.325 26.692,8.589 26.204,9.767 25.427,10.778 24.416,11.554 23.238,12.042 21.974,12.208 20.71,12.042 19.532,11.554 18.521,10.778 17.745,9.767 17.256,8.589 17.091,7.325 17.256,6.061 17.744,4.883 18.521,3.872 19.532,3.095 20.71,2.607"></polygon>
              <polygon class="ho-svg-poly" points="36.623,31.74 37.887,31.906 39.065,32.394 40.076,33.171 40.853,34.181 41.341,35.359 41.506,36.623 41.341,37.887 40.853,39.065 40.076,40.076 39.065,40.852 37.887,41.341 36.623,41.506 35.359,41.341 34.181,40.853 33.17,40.076 32.394,39.065 31.906,37.887 31.74,36.623 31.906,35.359 32.394,34.181 33.17,33.17 34.181,32.394 35.359,31.906"></polygon>
              <polygon class="ho-svg-poly" points="36.623,17.091 37.887,17.256 39.065,17.745 40.076,18.521 40.853,19.532 41.341,20.71 41.506,21.974 41.341,23.238 40.853,24.416 40.076,25.427 39.065,26.203 37.887,26.692 36.623,26.857 35.359,26.692 34.181,26.204 33.17,25.427 32.394,24.416 31.906,23.238 31.74,21.974 31.906,20.71 32.394,19.532 33.17,18.521 34.181,17.745 35.359,17.256"></polygon>
              <polygon class="ho-svg-poly" points="7.325,31.74 8.589,31.906 9.767,32.394 10.777,33.171 11.554,34.181 12.042,35.359 12.208,36.623 12.043,37.887 11.554,39.065 10.778,40.076 9.766,40.852 8.589,41.341 7.325,41.506 6.061,41.341 4.883,40.853 3.872,40.076 3.095,39.065 2.607,37.887 2.442,36.623 2.607,35.359 3.095,34.181 3.872,33.17 4.883,32.394 6.061,31.906"></polygon>
              <polygon class="ho-svg-poly" points="21.974,31.74 23.238,31.906 24.416,32.394 25.427,33.171 26.203,34.181 26.692,35.359 26.857,36.623 26.692,37.887 26.204,39.065 25.427,40.076 24.416,40.852 23.238,41.341 21.974,41.506 20.71,41.341 19.532,40.853 18.521,40.076 17.745,39.065 17.256,37.887 17.091,36.623 17.256,35.359 17.744,34.181 18.521,33.17 19.532,32.394 20.71,31.906"></polygon>
            </svg>
          )}
        </div>
        <div className="z-10 flex">
          <a href="/" className="social-icons"><FaDiscord color="white" size={20} /></a>
          <a href="/" className="social-icons"><FaTwitter color="white" size={20} /></a>
          <a href="/" className="social-icons"><AiFillInstagram color="white" size={20} /></a>
        </div>
      </nav>
      {open && (
        <div
          ref={navMenuRef}
          style={{opacity: 0, y: "-100%", transition: "opacity 0.3s, y 0.3s"}}
          id="nav-menu"
          className="lg:hidden fixed bg-white h-screen w-full flex flex-col justify-between z-2000"
        >
          <div id="nav-m-menu" style={{ pointerEvents: "all" }}>
            {/* Arkaplanı */}
            <div id="nav-m-menu-bg" style={{ opacity: "1" }}></div>
            {/* İçerik */}
            <div
              id="nav-m-menu-content"
              className="relative flex flex-col justify-between w-full"
            >
              <div className="flex-1 flex flex-col px-4 pt-[57px] pb-[31px]">
                <a
                  href="#"
                  className="mobile-m-product-items relative flex items-center justify-between first:mb-1 flex-1 pl-[25px]"
                >
                  <div
                    className="spread bg-[#f7f7f7] rounded-[4px] opacity-0"
                    style={{ opacity: "1", transform: "none" }}
                  ></div>
                  <div className="text-dec">
                    <div
                      className="text-[24px] tracking-[-0.01em] y"
                      style={{
                        fontFamily: "Roobert500",
                        transform: "translateY(0%) translateZ(0px)",
                      }}
                    >
                      World
                    </div>
                  </div>
                  <img
                    /*src={world}*/
                    className="sm:w-1/5 w-2/4 opacity-0 relative z-10"
                    style={{ opacity: "1", transform: "none" }}
                  />
                </a>
                <a
                  href="#"
                  className="mobile-m-product-items relative flex items-center justify-between second:mb-1 flex-1 pl-[25px]"
                >
                  <div
                    className="spread bg-[#f7f7f7] rounded-[4px] opacity-0"
                    style={{ opacity: "1", transform: "none" }}
                  ></div>
                  <div className="text-dec">
                    <div
                      className="text-[24px] tracking-[-0.01em] y"
                      style={{
                        fontFamily: "Roobert500",
                        transform: "translateY(0%) translateZ(0px)",
                      }}
                    >
                      Community
                    </div>
                  </div>
                  <img
                    /*src={community}*/
                    className="sm:w-1/5 w-2/4 opacity-0 relative z-10"
                    style={{ opacity: "1", transform: "none" }}
                  />
                </a>
                <a
                  href="#"
                  className="mobile-m-product-items relative flex items-center justify-between flex-1 pl-[25px]"
                >
                  <div
                    className="spread w-full bg-[#f7f7f7] rounded-[4px] opacity-0"
                    style={{ opacity: "1", transform: "none" }}
                  ></div>
                  <div className="text-dec">
                    <div
                      className="text-[24px] tracking-[-0.01em] y"
                      style={{
                        fontFamily: "Roobert500",
                        transform: "translateY(0%) translateZ(0px)",
                      }}
                    >
                      Mint
                    </div>
                  </div>
                  <img
                    /*src={mint}*/
                    className="sm:w-1/5 w-2/4  opacity-0 relative z-10"
                    style={{ opacity: "1", transform: "none" }}
                  />
                </a>
              </div>
              <div>
                <div
                  id="navigationElements"
                  className="flex w-full md:gap-x-4 px-4"
                >
                  <div className="w-full pb-[75px] grid grid-cols-4 gap-x-4 md:w-1/2">
                    <div className="col-span-1">
                      <div className="text-dec">
                        <div
                          className="font-normal text-[12px] text-black/60 mb-[3px] leading-[32px] y"
                          style={{
                            fontFamily: "Roobert400",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Home
                        </div>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Link
                        </a>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Link
                        </a>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Link
                        </a>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="text-dec">
                        <div
                          className="font-normal text-[12px] text-black/60 mb-[3px] leading-[32px] y"
                          style={{
                            fontFamily: "Roobert400",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Home
                        </div>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Link
                        </a>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Link
                        </a>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Link
                        </a>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Link
                        </a>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="text-dec">
                        <div
                          className="font-normal text-[12px] text-black/60 mb-[3px] leading-[32px] y"
                          style={{
                            fontFamily: "Roobert400",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Social
                        </div>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Discord
                        </a>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Twitter
                        </a>
                      </div>
                      <div className="text-dec">
                        <a
                          href="#"
                          className="font-medium text-[14px] text-black/60 last:mb-0 mb-[13px] leading-[28px] y"
                          style={{
                            fontFamily: "Roobert500",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Instagram
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2 hidden md:block">
                    <h2 className="text-[20px] font-bold leading-[28px] mb-[18px] max-w-[254px]">
                      <span className="text-dec block">
                        <span
                          className="y"
                          style={{
                            fontFamily: "Roobert600",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Subscribe to the
                        </span>
                      </span>
                      <span className="text-dec block">
                        <span
                          className="y"
                          style={{
                            fontFamily: "Roobert600",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Nindou™ Newsletter
                        </span>
                      </span>
                    </h2>
                    <p className="text-[13px] leading-[24px] max-w-[243px] mb-[28px]">
                      <span className="text-dec">
                        <span
                          className="y"
                          style={{
                            fontFamily: "Roobert400",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          Latest news, announcements
                        </span>
                      </span>
                      <span className="text-dec">
                        <span
                          className="y"
                          style={{
                            fontFamily: "Roobert400",
                            transform: "translateY(0%) translateZ(0px)",
                          }}
                        >
                          and updates direct to your inbox
                        </span>
                      </span>
                    </p>
                    <a
                      href="/newsletter"
                      className="icon-container hover:bg-gray-700 footer-newsletter-link group relative w-[100px] h-10 rounded-[20px] inline-flex will-change-[background-color] bg-black duration-200 transition-colors ease-ease items-center  bottom-5"
                      type="submit"
                      aria-label="Submit"
                    >
                      <FaArrowRightLong className="w-[30px] relative left-14 okey" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="mx-4 h-[1px] bg-black/10 opacity-0"
                style={{ opacity: "1", transform: "none" }}
              ></div>
              <div
                id="mobile-m-footer"
                className="text-black font-400 mx-4 flex items-center justify-between h-[56px]"
              >
                <div className="text-dec">
                  <p
                    className="text-[14px] leading-[28px] y"
                    style={{
                      fontFamily: "Roobert500",
                      transform: "translateY(0%) translateZ(0px)",
                    }}
                  >
                    Nindou™ Inc.
                  </p>
                </div>
                <div className="text-dec">
                  <small
                    className="block text-[14px] leading-[28px] y"
                    style={{
                      fontFamily: "Roobert500",
                      transform: "translateY(0%) translateZ(0px)",
                    }}
                  >
                    All rights reserved
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MobileNavbar;

// Eski mobile menü
//   <div className="flex flex-col px-4 pt-[57px] pb-[31px]">
//     <a
//       href="#"
//       className="relative flex items-center justify-between mb-1 opacity-1 left-0 top-0"
//     >
//       <div
//         className="bg-[#f7f7f7f7] grid grid-cols-2"
//         style={{ height: `${boxHeight}px`, width: "100%" }}
//       >
//         <div className="flex justify-start items-center">
//           <h1 className="font-bold ml-2">World</h1>
//         </div>
//         <div className="overflow-hidden">
//           <img
//             src={world}
//             className="sm:w-2/4 w-full  float-right h-full"
//           />{" "}
//         </div>
//       </div>
//     </a>
//     <a
//       href="#"
//       className="relative h-full flex items-center justify-between mb-1 flex-1 "
//     >
//       <div
//         className="bg-[#f7f7f7f7] grid grid-cols-2"
//         style={{ height: `${boxHeight}px`, width: "100%" }}
//       >
//         <div className="flex justify-start items-center">
//           <h1 className="font-bold ml-2">Community</h1>
//         </div>
//         <div className="overflow-hidden">
//           <img
//             src={community}
//             className="sm:w-2/4 w-full float-right h-full"
//           />{" "}
//         </div>
//       </div>
//     </a>
//     <a
//       href="#"
//       className="md:flex relative flex items-center justify-between mb-1 flex-1 "
//     >
//       <div
//         className="bg-[#f7f7f7f7] grid grid-cols-2"
//         style={{ height: `${boxHeight}px`, width: "100%" }}
//       >
//         <div className="flex justify-start items-center">
//           <h1 className="font-bold ml-2">Mint</h1>
//         </div>
//         <div className="overflow-hidden">
//           <img
//             src={mint}
//             className="sm:w-2/4 w-full float-right h-full"
//           />{" "}
//         </div>
//       </div>
//     </a>
//   </div>
//   {/* Liste elemanları */}
//   <div className="flex w-full gap-x-4 px-4 justify-between">
//     <div className="sm:m-0 m-auto gap-5 flex  sm:col-span-1 col-span-2">
//       <div>
//         <h1 className="text-[12px] duration-300 text-gray-500 ">
//           Company
//         </h1>
//         <ul>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               About
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               Terms
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               Privacy
//             </a>
//           </li>
//         </ul>
//       </div>
//       <div>
//         <h1 className="text-[12px] duration-300 text-gray-500 ">
//           Resources
//         </h1>
//         <ul>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               Support
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               Media Kit
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               Downloads
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               Newsletter
//             </a>
//           </li>
//         </ul>
//       </div>
//       <div>
//         <h1 className="text-[12px] duration-300 text-gray-500 ">
//           Social
//         </h1>
//         <ul>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               Instagram
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               className="text-[12px] text-black hover:text-gray-600"
//             >
//               Twitter
//             </a>
//           </li>
//         </ul>
//       </div>
//     </div>
//     <div
//       className="ml-10 sm:block hidden  xsm:w-full w-1/2"
//     >
//       <h1 className="font-bold text-[20px] w-2/4 mb-[18px]">
//         Subscribe to the Opal Newsletter
//       </h1>
//       <p className="text-[13px] text-[#767676] mb-[18px]">
//         Latest news, musings, announcements
//         <br /> and updates direct to your inbox.{" "}
//       </p>
//       {/* Buttona animasyon eklenecek. */}
//       <button className="w-[100px] h-10 rounded-[20px] icon-container bg-black hover:bg-gray-700 ">
//         <FaLongArrowAltRight
//           className="relative left-7 okey"
//           size={30}
//         />
//       </button>
//     </div>
//   </div>
//   <div className="border-t-[2px] m-3 h-[5%] font-400 flex  items-center  justify-between">
//     <div className="mt-1 text-[14px]">Opal Camera Inc.</div>
//     <div className="mt-1 text-[14px]">All rights reserved</div>
//   </div>
// </div>
