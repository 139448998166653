import React from "react";
import "../App.css";
import { FaArrowRightLong, FaArrowUp } from "react-icons/fa6";
import nindouText from "../assets/img/nindou-text.svg";

function Footer() {
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  // 30'u 50'ye çektiğimizde biraz daha yavaş bir hareketle yukarı çıkmasını sağlıyoruz
  // bu fonksiyon da kullanılabilir.
  // const scrollToTop = () => {
  //   const c = document.documentElement.scrollTop || document.body.scrollTop;
  //   if (c > 0) {
  //     window.requestAnimationFrame(scrollToTop);
  //     window.scrollTo(0, c - c / 30);
  //   }
  // };


  return (
    <footer className="overflow-hidden relative block bg-white">
      <div className="px-2 md:px-4">
        {/* image buraya gelecek */}
        <div className="relative pt-[31.96px] pb-[24.66px] mb:pb-0">
          <img src={nindouText} />
        </div>
        {/*  buttonlu kısım */}
        <div className="max-w-[1616px] mx-auto lg:mt-[-10px] xl:mt-[-13px] relative grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-x-5">
          <div className="hidden md:block lg:col-start-1 col-span-4 xl:col-start-2 relative border-t border-t-black/10 group-[.is]/theme-dark:border-t-black/20 pt-[18px] lg:pt-[41px] 2xl:pt-[35px]">
            <p
              style={{ fontFamily: "Roobert600" }}
              className="text-[20px] leading-[28px] mb-[2px] max-w-[254px]"
            >
              Subscribe to the
              <br />
              Nindou™ Newsletter
            </p>
            <p
              style={{ fontFamily: "Roobert400" }}
              className="text-[13px] 2xl:text[14px] leading-[24px] text-gray-500 max-w-[247px] mb-[23px"
            >
              Latest news, announcements and updates direct to your
              inbox.
            </p>
            <a
              id="arrowButton"
              href="/newsletter"
              className="icon-container footer-newsletter-link group relative w-[100px] h-10 rounded-[20px] inline-flex will-change-[background-color] bg-black hover:bg-[#948e81] group-[.is]/theme-dark:hover:bg-[#948e81] group-hover/theme-dark:deep:fill-white duration-200 transition-colors ease-ease items-center deep:fill-white deep:hover:fill-black top-5"
              type="submit"
              aria-label="Submit"
            >
              <FaArrowRightLong className="w-[30px] relative left-14 okey" />
            </a>
          </div>
          {/* Liste elemanları */}
          <div className="col-span-4 lg:col-span-8 xl:col-span-4 md:col-start-6 lg:col-start-9 xl:col-start-8 px-2 md:px-0 xl:gap-x-20 gap-x-5  border-t border-t-black/10 group-[.is]/theme-dark:border-t-black/20 pt-[29px] lg:pt-[37px] xl:pt-[41px] 2xl:pt-[35px] pb-[110px] md:pb-0 footer-links">
            <div style={{ fontFamily: "Roobert500" }}>
              <div
                style={{ fontFamily: "Roobert400" }}
                className="text-[13px] 2xl:text-[14px] text-black/40 mb-[20px]"
              >
                Nindou
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Home
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                About
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Explorer
              </div>
            </div>
            <div style={{ fontFamily: "Roobert400" }}>
              <div
                style={{ fontFamily: "Roobert400" }}
                className="text-[13px] 2xl:text-[14px] text-black/40 mb-[20px]"
              >
                Club
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Buy
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Community
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Careers
              </div>
            </div>
            <div style={{ fontFamily: "Roobert400" }}>
              <div
                style={{ fontFamily: "Roobert400" }}
                className="text-[13px] 2xl:text-[14px] text-black/40 mb-[20px]"
              >
                Resources
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Support
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Downloads
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Terms
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Privacy
              </div>
            </div>
            <div style={{ fontFamily: "Roobert400" }}>
              <div
                style={{ fontFamily: "Roobert400" }}
                className="text-[13px] 2xl:text-[14px] text-black/40 mb-[20px]"
              >
                Social
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Discord
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Twitter
              </div>
              <div
                href="#"
                className="cursor-pointer font-medium 2xl:text-[14px] text-[13px] text-black hover:text-black/50 mb-[9px]"
              >
                Instagram
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[1616px] mx-auto md:pt-[64px] grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-x-4 pt-5">
          <div className="relative xl:col-start-2 xl:col-span-10 col-span-4 md:col-span-8 lg:col-span-12 items-center grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 xl:grid-cols-10 gap-x-4 leading-[0]">
            <div className="group-[.is]/theme-dark:border-t-black/20 col-span-4 border-t border-t-black/10 text-[14px] font-medium flex justify-between w-full md:pt-0 h-[65px] md:h-[50px] items-center leading-[1] px-2 md:px-0">
              <div style={{fontFamily:"Roobert500"}}>Nindou™ Inc.</div>
              <div style={{fontFamily:"Roobert500"}}>All rights reserved.</div>
            </div>
            <div className="items-center md:col-span-4 lg:col-span-6 xl:col-span-6 hidden md:flex justify-end h-[65px] md:h-[50px] leading-[0] xl:relative xl:left-[210px] 2xl:relative 2xl:left-0">
              <button
                onClick={scrollToTop}
                className="bg-slate-100 p-3 hover:bg-[#948e81] rounded-full hover:text-white hover:duration-300 duration-300"
              >
                <FaArrowUp />
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
