import React, { useEffect, useState } from "react";
import { FaArrowRightLong, FaCheck } from "react-icons/fa6";
import "../assets/styles/fonts.css";
import postEmail from "../services/api";
import "../assets/styles/search-input.css";
import gsap from "gsap";

function SearchBar() {
  // States
  const [inputValue, setInputValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [placeHolder, setPlaceHolder] = useState("Newsletter");
  const [lineColor, setLineColor] = useState("bg-white");
  const [buttonIcon, setButtonIcon] = useState(true);
  // Fonksiyonlar

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // input'un değeri silindiğinde otomatik rengi değiştiriyor.
  useEffect(() => {
    if (inputValue.length === 0) {
      setLineColor("bg-white");
    }
  }, [inputValue]);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      if (isValidEmail(inputValue)) {
        const response = await postEmail(inputValue);
        console.log("Response: ", response.data);
        setInputValue("");
        setButtonIcon(false);
        setPlaceHolder("You are subscribed.");
        setIsDisabled(true);
      } else {
        setLineColor("bg-red-500");
      }
    } catch (error) {
      console.log("error");
    }
  };

  // email kontrolü yapmak için bir fonksiyon
  // çıktı olarak true veya false veriyor.
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // buttonun değişme animasyonu
  // animasyonları daha da özelleştirebiliriz buradan...
  useEffect(() => {
    const buttonGsap = gsap.timeline();

    if (!buttonIcon) {
      buttonGsap
        .from("#check-icon", {
          opacity: 0,
          stagger: 0.3,
          y: "+=3",
          animationDelay: 0.3,
        })
        .to("#check-icon", {
          opacity: 1,
          stagger: 0.3,
          y: "0",
          animationDelay: 0.3,
        });
    }
  }, [buttonIcon]);

  return (
    <>
      <form onSubmit={sendEmail} className="flex m-auto">
        <div className="w-[calc(75% - 4px)] h-[47px] overflow-hidden relative">
          {/* input */}
          {/* Font verilecek */}
          <div
            style={{ pointerEvents: "all", lineHeight: "44px" }}
            className="text-[24px] text-white overflow-hidden font[500] h-[44px] w-[100%]"
          >
            <div className="opacity-0 h-[100%] w-[100%] left-0 absolute top-0">
              You are subscribed.
            </div>
            <div
              style={{
                transform: "translate3d(0px, 0%, 0px)",
                pointerEvents: "all",
                transition: "width var(--o6)",
              }}
              className="text-transparent h-[100%] left-0 absolute top-0"
            ></div>
            <input
              disabled={isDisabled}
              placeholder={placeHolder}
              className="placeholder:text-[24px] text-[24px] placeholder:text-white y newsletter-form-input-js newsletter-form-input-element"
              spellCheck="false"
              style={{ transform: "translate3d(0px, 0%, 0px)" }}
              onChange={handleInputChange}
              value={inputValue}
            />
            <div
              className={`${
                inputValue.trim()
                  ? `${lineColor} h-[2px] absolute left-0 bottom-0 w-full`
                  : "input-line"
              }`}
            ></div>
          </div>
          {/* line */}
        </div>

        {buttonIcon && (
          <button
            className="hover:opacity-100 hover:bg-gray-700 relative icon-container m-0 p-0  ml-[16px] w-[130px] bg-[hsla(0,0%,100%,.1)] rounded-[20px] h-[40px]"
            type="submit"
          >
            <FaArrowRightLong className="w-[30px] bottom-[0.2px] absolute okey" />
          </button>
        )}
        {!buttonIcon && (
          <button
            disabled
            className="hover:cursor-default relative icon-container bg-pink-500  m-0 p-0  ml-[16px] w-[130px] rounded-[20px] h-[40px]"
          >
            <FaCheck
              id="check-icon"
              className="w-[30px] absolute bottom-[0.5px] left-9 xl:left-12"
              color="black"
            />
          </button>
        )}
      </form>
      <div
        style={{ fontFamily: "Roobert500i" }}
        className="mt-[26px] text-[12px] text-[#767676]"
      >
        <span>
          By signing up, I agree with the data protection policy of Nindou.
        </span>
      </div>
    </>
  );
}

export default SearchBar;
