import React, { useEffect, useRef } from "react";
import "../assets/styles/all-styles.css"; // spinner style
import gsap from "gsap";

const Spinner = (props) => {
  const logoRef = useRef(null);

  console.log(props.classProps);
  useEffect(() => {
    const gsapTimeLine = gsap.timeline();
    if (props.classProps === "stop") {
      gsapTimeLine
        .from("#lo", {
          opacity: 1,
          duration: 0.6,
        })
        .to("#lo", {
          opacity: 0,
          duration: 0.6,
        });
    } else {
      console.log("else logged in");
    }
  }, [props.classProps]);

  return (
    <div id="lo" ref={logoRef}>
      <div id="lo-bg" />
      <svg id="lo-svg" viewBox="0 0 22 22" className={props.classProps}>
        <g id="nindou">
          <path d="M10.64,11.94v.15h0c0-.29-.15-.73-.15-1.03s.29-.88.73-.88c.59,0,1.32,0,1.91-.15h1.32c.15,0,.15-.15.15-.15v-1.03c0-.29.15-.59.44-.73.15-.15.44,0,.59.29v1.03c0,.15,0,.44-.15.59,0,.15,0,.29.15.29h1.91c.29.29.59.15.88.15h.73c.15,0,.59.29.59.44,0,.73,0,1.47-.15,2.35-.15,1.17-.29,2.35-.88,3.37-.44.73-.88,1.32-1.61,1.76-.59.29-1.32.44-1.91.73-.88.29-1.76.29-2.64.29h-2.05c-.15,0-.44-.15-.59-.15s-.29-.15-.44-.15-.29,0-.15-.29c0,0,0-.15-.15-.15,0-.15-.15-.15-.15-.29l.15-.15h.29c.88.29,1.91.15,2.79,0,.88-.15,1.61-.29,2.49-.44,1.47-.29,2.35-1.32,2.79-2.64.29-.73.29-1.61.59-2.49,0-.15,0-.44.15-.59,0-.15,0-.29-.15-.29h-2.2c-.73,0-1.61-.15-2.35-.15h-1.76c-.15,0-.15.15-.15.29v.88c0,.29-.15.44-.15.73-.15-.15-.44-.15-.59-.29v-.15c-.15-.73-.15-.88-.29-1.17h0Z" />
          <path d="M3.17,13.99v.29c0,1.32.15,2.64.15,3.96,0,.29,0,.59-.15.88,0,.29-.15.44-.29.73,0,.15-.15.15-.29.15s-.15,0-.29-.15q-.59-.29-.59-.44c0-.29-.15-.59-.15-.88v-6.6c0-.88.15-1.61.15-2.49,0-.15.15-.44.15-.59,0-.29.29-.44.44-.44s.29.15.29.29c0,.29.15.73.15,1.03,0,.59,0,1.17.15,1.76v.29c0,.15,0,.29.29.29.15,0,.29.15.44.29,1.17.73,2.2,1.61,3.08,2.64.29.29.44.59.59,1.03.15.15-.15.44-.29.59-.15,0-.29,0-.44.15-.29.29-.59.29-.88,0-.44-.44-.88-1.03-1.17-1.47-.44-.44-.73-.88-1.17-1.32.15.15,0,.15-.15,0Z" />
          <path d="M6.39,11.79c0,.29-.44.73-.59.73-.29,0-.44-.15-.59-.29-.29-.59-.59-1.32-.88-1.91-.15-.29.15-.29.15-.29h.44c.44.44.88.88,1.17,1.32.15.15.29.29.29.44Z" />
          <path d="M6.68,10.62v.29c-.29.15-.44,0-.59-.29-.44-.73-.88-1.32-1.32-2.05v-.29h.44c.59.29,1.17.88,1.61,1.32.15.15.29.44.29.73,0,.15-.15.29-.15.44.15,0-.15,0-.29-.15Z" />
          <path d="M11.23,7.1h.15c-.15,0-.29,0-.29-.15l-.15-.15s0-.15.15-.15c.15-.15.44-.15.59-.29h.29c.59-.29,1.17-.44,1.76-.73.44-.29.88-.73,1.17-1.03.59-.59,1.17-1.03,1.61-1.61.44-.44.73-1.03,1.17-1.47.29-.29.59-.44.88-.59,0,0,.15.15.29.15l.15-.15h0c-.15.29.15.59.29.88,0,.44-.15.73-.29.88-.59.59-1.03,1.32-1.61,1.91-.44.44-.88.88-1.47,1.17-.15.29-.44.59-.88.73-.88.44-1.91.73-2.93.73-.29,0-.59,0-.88-.15,0,.15,0,0,0,0Z" />
          <path d="M14.75,2.41q.15,0,0,0c.29.15.44.15.29.44v.15c-.88.15-1.61-.15-2.2-.73-.44-.44-.88-.88-1.32-1.32-.15-.15-.29-.44,0-.59.15,0,0-.29.15-.29s.15-.15.29,0,.44.29.59.44l1.76.88c.15.15.44.15.59.29.15.15.29.29.29.59,0,0-.15.15-.44.15Z" />
          <path d="M10.2,4.61c-.59.15-1.17.29-1.76.29H.23v-.15h.44-.29c-.44,0-.44-.15-.29-.29.15-.44,1.03-.88,1.47-.73h.44c.59-.29,1.17-.59,1.91-.59,1.03,0,2.05-.15,2.93-.15,0,0,.15,0,.15.15h.44c0-.15.15-.15.15-.15h.88-.29c.59.15,1.32,0,1.76.44h-.15c.15.15.29.29.44.59v.15h-.44c.29.29.29.44.44.44Z" />
          <path d="M2.14,1.53s.15,0,.15-.15c0,0-.15-.15-.29-.15.29-.15.44-.29.59-.29.29,0,.73,0,1.17-.15.73,0,1.32-.15,2.05-.15.29,0,.73,0,1.03.15.15,0,.15.15.15.15q.15.15.29.29c-.15,0-.29,0-.44.15h0c.15,0,.44.15.59.15q0,.15-.15.15c-.44.44-.88.88-1.32.88h-1.32c-.59,0-1.03-.15-1.61-.29-.29,0-.59,0-.73-.44,0-.15-.15-.15-.15-.29Z" />
        </g>
        <g id="lo1">
          <path d="M17.82,13.13c-1,.25-2,.5-3,.5H.82v-.25h.75-.5c-.75,0-.75-.25-.5-.5.25-.75,1.75-1.5,2.5-1.25h.75c1-.5,2-1,3.25-1,1.75,0,3.5-.25,5-.25,0,0,.25,0,.25.25h.75c0-.25.25-.25.25-.25h1.5-.5c1,.25,2.25,0,3,.75h-.25c.25.25.5.5.75,1v.25h-.75c.5.5.5.75.75.75Z" />
          <path d="M4.07,7.87s.25,0,.25-.25c0,0-.25-.25-.5-.25.5-.25.75-.5,1-.5.5,0,1.25,0,2-.25,1.25,0,2.25-.25,3.5-.25.5,0,1.25,0,1.75.25.25,0,.25.25.25.25q.25.25.5.5c-.25,0-.5,0-.75.25h0c.25,0,.75.25,1,.25q0,.25-.25.25c-.75.75-1.5,1.5-2.25,1.5h-2.25c-1,0-1.75-.25-2.75-.5-.5,0-1,0-1.25-.75,0-.25-.25-.25-.25-.5Z" />
        </g>
        <g id="lo2">
          <path d="M2.5,15.93h.25c-.25,0-.5,0-.5-.25l-.25-.25s0-.25.25-.25c.25-.25.75-.25,1-.5h.5c1-.5,2-.75,3-1.25.75-.5,1.5-1.25,2-1.75,1-1,2-1.75,2.75-2.75.75-.75,1.25-1.75,2-2.5.5-.5,1-.75,1.5-1,0,0,.25.25.5.25l.25-.25h0c-.25.5.25,1,.5,1.5,0,.75-.25,1.25-.5,1.5-1,1-1.75,2.25-2.75,3.25-.75.75-1.5,1.5-2.5,2-.25.5-.75,1-1.5,1.25-1.5.75-3.25,1.25-5,1.25-.5,0-1,0-1.5-.25,0,.25,0,0,0,0Z" />
          <path d="M8.5,7.93q.25,0,0,0c.5.25.75.25.5.75v.25c-1.5.25-2.75-.25-3.75-1.25-.75-.75-1.5-1.5-2.25-2.25-.25-.25-.5-.75,0-1,.25,0,0-.5.25-.5s.25-.25.5,0,.75.5,1,.75l3,1.5c.25.25.75.25,1,.5.25.25.5.5.5,1,0,0-.25.25-.75.25Z" />
        </g>
        <g id="lo3">
          <path d="M6.97,9.75v.5c0,2.25.25,4.5.25,6.75,0,.5,0,1-.25,1.5,0,.5-.25.75-.5,1.25,0,.25-.25.25-.5.25s-.25,0-.5-.25q-1-.5-1-.75c0-.5-.25-1-.25-1.5V6.25c0-1.5.25-2.75.25-4.25,0-.25.25-.75.25-1,0-.5.5-.75.75-.75s.5.25.5.5c0,.5.25,1.25.25,1.75,0,1,0,2,.25,3v.5c0,.25,0,.5.5.5.25,0,.5.25.75.5,2,1.25,3.75,2.75,5.25,4.5.5.5.75,1,1,1.75.25.25-.25.75-.5,1-.25,0-.5,0-.75.25-.5.5-1,.5-1.5,0-.75-.75-1.5-1.75-2-2.5-.75-.75-1.25-1.5-2-2.25.25.25,0,.25-.25,0Z" />
          <path d="M12.47,6c0,.5-.75,1.25-1,1.25-.5,0-.75-.25-1-.5-.5-1-1-2.25-1.5-3.25-.25-.5.25-.5.25-.5h.75c.75.75,1.5,1.5,2,2.25.25.25.5.5.5.75Z" />
          <path d="M12.97,4v.5c-.5.25-.75,0-1-.5-.75-1.25-1.5-2.25-2.25-3.5v-.5h.75c1,.5,2,1.5,2.75,2.25.25.25.5.75.5,1.25,0,.25-.25.5-.25.75.25,0-.25,0-.5-.25Z" />
        </g>
        <g id="lo4">
          <path d="M2.75,6.92v.25h0c0-.5-.25-1.25-.25-1.75s.5-1.5,1.25-1.5c1,0,2.25,0,3.25-.25h2.25c.25,0,.25-.25.25-.25v-1.75c0-.5.25-1,.75-1.25.25-.25.75,0,1,.5v1.75c0,.25,0,.75-.25,1,0,.25,0,.5.25.5h3.25c.5.5,1,.25,1.5.25h1.25c.25,0,1,.5,1,.75,0,1.25,0,2.5-.25,4-.25,2-.5,4-1.5,5.75-.75,1.25-1.5,2.25-2.75,3-1,.5-2.25.75-3.25,1.25-1.5.5-3,.5-4.5.5h-3.5c-.25,0-.75-.25-1-.25s-.5-.25-.75-.25-.5,0-.25-.5c0,0,0-.25-.25-.25,0-.25-.25-.25-.25-.5l.25-.25h.5c1.5.5,3.25.25,4.75,0,1.5-.25,2.75-.5,4.25-.75,2.5-.5,4-2.25,4.75-4.5.5-1.25.5-2.75,1-4.25,0-.25,0-.75.25-1,0-.25,0-.5-.25-.5h-3.75c-1.25,0-2.75-.25-4-.25h-3c-.25,0-.25.25-.25.5v1.5c0,.5-.25.75-.25,1.25-.25-.25-.75-.25-1-.5v-.25c-.25-1.25-.25-1.5-.5-2h0Z" />
        </g>
      </svg>
    </div>
  );
};

export default Spinner;
