import gsap from "gsap";


// Soldan sağa doğru giden animasyon
function fadeRight(elements, comp) {
  const navbarStared = gsap.timeline();
  navbarStared
    .from(elements, {
      opacity: 0,
      x: "-=30",
      stagger: 0.1,
    })
    .to(
      elements,
      {
          opacity: 1,
          delay: 0.3,
          stagger: 0.1, // Her öğe için 0.1 saniyelik bir gecikme
      },
      comp
    );
}

export default fadeRight;
