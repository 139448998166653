import React, { useState, useEffect, Suspense, useRef } from "react";
import { BrowserRouter, Routes, Route, useFetcher } from "react-router-dom";
import Home from "./views/Home";
import "./App.css";
import Spinner from "./components/Spinner";
import community from "./assets/img/community.png";
import hero3xl from "./assets/img/hero-3xl.webp";
import hero2xl from "./assets/img/hero-2xl.webp";
import herolg from "./assets/img/hero-lg.webp";
import heromd from "./assets/img/hero-md.webp";
import herosm from "./assets/img/hero-sm.webp";
import heroxl from "./assets/img/hero-xl.webp";
import mint from "./assets/img/mint.png";
import world from "./assets/img/world.png";
import gsap from "gsap";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [openPage, setOpenPage] = useState(true);

  useEffect(() => {
    const preloadContent = async () => {
      try {
        const imagePromises = [];
        const imageUrls = [
          community,
          world,
          mint,
          hero3xl,
          hero2xl,
          heroxl,
          herolg,
          heromd,
          herosm,
        ];

        imageUrls.forEach((url) => {
          const imagePromise = new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = resolve;
            image.onerror = reject;
            image.src = url;
          });
          imagePromises.push(imagePromise);
        });

        await Promise.all(imagePromises);

        setLoading(false);
      } catch (error) {
        console.error("Image error:", error);
      } finally {
        setLoading(false);
      }
    };

    preloadContent();
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setOpenPage(false);
      }, 1000);
    }
  }, [loading]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Spinner />}>

              {/* Ref doğru bileşene ekleniyor */}
              {openPage ? (
                <Spinner classProps={loading ? "fx" : "stop"} />
              ) : (
                <Home />
              )}
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
